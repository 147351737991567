import React from 'react';
import { FaPhone, FaLocationArrow,FaEnvelope } from 'react-icons/fa6';

const Footer = () => {
  return (
    <div className='flex flex-col bg-[#0B3353] text-[#ffffff]/80'>
        <div className='flex py-12 pl-24 gap-52'>
            <span className='flex flex-col gap-4'>
                <span className='text-3xl font-medium'>Quick Links</span>
                <span className='flex flex-col gap-5'>
                    <span className='text-lg'>Home</span>
                    <span className='text-lg'>What we do</span>
                    <span className='text-lg'>News & Insights</span>
                    <span className='text-lg'>Contact us</span>
                </span>
            </span>
            <span className='flex flex-col gap-4'>
                <span className='text-3xl font-medium'>Phone</span>
                <span className='flex gap-2 items-center'>
                    <span className='w-5 h-5'>
                        <FaPhone className='w-full h-full'/>
                    </span>
                    <span className='text-lg'>+234 000 000 0000</span>
                </span>
            </span>
            <span className='flex flex-col gap-4'>
                <span className='text-3xl font-medium'>Get In Touch</span>
                <span className='flex flex-col gap-3'>
                    <span className='flex gap-2 items-center'>
                        <span className='w-5 h-5'>
                            <FaLocationArrow className='w-full h-full'/>
                        </span>
                        <span className='text-lg'>6, Tunde Fisayo, Lekki Phase 1, Lagos</span>
                    </span>
                    <span className='flex gap-2 items-center'>
                        <span className='w-5 h-5 '><FaEnvelope className='w-full h-full'/></span>
                        <span className='text-lg'>Info@hanoverpartners.com</span>
                    </span>
                </span>
            </span>
        </div>
        <div className='flex py-6 border-t items-center justify-center text-sm'>© 2024 HanoverPartners. All Rights Reserved.</div>
    </div>
  )
}

export default Footer;