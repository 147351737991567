import React from 'react';
import NavBar from './NavBar';
import Img from '../assets/han2.png';
import Img2 from '../assets/han6.png';
import Img3 from '../assets/img2.png';

const AboutContent = () => {
  return (
    <div>
        <NavBar />
        <div className='flex h-[31rem] justify-center bg-[#000000]/80 bg-cover bg-center relative'>
            <div className="absolute w-full h-full bg-[#0B3353]"><img className='w-full h-full opacity-50' src={Img3} alt='' ></img></div>
            <span className='flex items-center gap-10 z-10'>
                <hr className='w-80 text-[#ffffff]'></hr>
                <span className='flex flex-col gap-3'>
                    <span className='text-5xl font-bold text-center text-[#ffffff]/90'>Our Services</span>
                </span>
                <hr className='w-80 text-[#ffffff]'></hr>
            </span>
        </div>
        <div className='flex items-center gap-12 justify-center py-12'>
            <span className='flex flex-col gap-2'>
                <span className='text-2xl font-medium w-[35rem] text-[#0B3353]'>We use our global access and local expertise to generate alternative asset investments that create long term value. </span>
                <span className='text-2xl w-[35rem] tracking-wide text-[#0B3353]/80'>We combine decades of financial markets and alternative investment experience, driving capital where it creates superior returns.</span>
            </span>
            <span className='w-[30rem] h-[30rem] bg-[#0B3353]'><img src={Img} alt='' className='w-full h-full object-cover'></img></span>
        </div>
        <div className='flex flex-col py-16 bg-[#0B3353]/80'>
            <span className='flex  px-24'>
                <span className='flex flex-col w-[50%] gap-2 items-center text-center border-[#ffffff]/80 border-r py-5 px-5 '>
                    <span className='text-3xl font-semibold text-[#ffffff]/70 tracking-wide'>Global Markets</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>We are experts in investments and structures around rates, credit, commodities, foreign exchange, and their derivatives globally.</span>
                </span>
                <span className='flex flex-col w-[50%] gap-2 items-center text-center py-5 px-5 border-[#ffffff]/80 border-l'>
                    <span className='text-3xl font-semibold text-[#ffffff]/70 tracking-wide'>Alternative Investments</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>We find solutions in natural resources, real estate, private equity, infrastructure, credit, hedge funds as broad categories.</span>
                </span>
            </span>
            <span className=' w-full h-[20rem] bg-white mt-5'><img src={Img2} alt='' className='w-full h-full object-cover'></img></span>
            <span className='flex justify-center px-24'>
                <span className='flex flex-col w-[rem]  gap-2 text-center py-5 px-5 '>
                    <span className='text-3xl font-semibold text-[#ffffff]/70 tracking-wide'>Advisory</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>We offer Financial Advisory, Transaction advisory and Research Advisory. We advise individuals, corporates, governments looking to create long term sustainable value.</span>
                </span>
            </span>
            <span className='flex items-center justify-center'>
                <span className='flex flex-col gap-2 items-center text-center border-[#ffffff]/80 border-r py-5 px-16 '>
                    <span className='text-3xl font-semibold text-[#ffffff]/70 tracking-wide'>Transaction Advisory</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>Due Diligence</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>Deal Negotiation</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>Transaction Structuring</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>Project/Bid Preparation</span>
                </span>
                <span className='flex flex-col gap-2 items-center text-center py-5 px-16 border-[#ffffff]/80 border-l'>
                    <span className='text-3xl font-semibold text-[#ffffff]/70 tracking-wide'>Financial Advisory</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>Business planning & Strategy</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>Financial Modelling</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>Business Valuation</span>
                    <span className='text-xl font-normal text-[#ffffff]/70 tracking-wide'>Mergers & Acquisition</span>
                </span>
            </span>
        </div>
    </div>

  )
}

export default AboutContent;