import React from 'react';
import logo from '../assets/Logo.png';
import { IoLogoFacebook, IoLogoInstagram} from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <div className='flex justify-between px-20 py-5 items-center bg-[#0B3353]'>
        <span className=''><img src={logo} className='w-44' alt=''></img></span>
        <div className='flex gap-10 justify-center'>
            <Link to='/'><span className='text-lg font-normal text-[#ffffff]/90 '>Home</span></Link>
            <Link to='/about'><span className='text-lg font-normal text-[#ffffff]/90 '>What we do</span></Link>
            <Link to='/news'><span className='text-lg font-normal text-[#ffffff]/90 '>News & Insights</span></Link>
            <span className='text-lg font-normal text-[#ffffff]/90 '>Contact Us</span>
        </div>
        <span className='flex gap-3 justify-center'>
            <IoLogoFacebook size={30} className='text-[#ffffff]/90'/>
            <IoLogoInstagram size={30} className='text-[#ffffff]/90'/>
            <FaXTwitter size={30} className='text-[#ffffff]/90'/>
        </span>
    </div>
  )
}

export default NavBar