import React from 'react';
import NavBar from './NavBar';
import Img2 from '../assets/han3.png';
import Img3 from '../assets/han4.png';
import Img4 from '../assets/han5.png';
import Img5 from '../assets/img3.png';

const NewsContent = () => {
  return (
    <div>
        <NavBar />
        <div className='flex h-[31rem] justify-center bg-[#000000]/80 bg-cover bg-center relative'>
            <div className="absolute w-full h-full bg-[#0B3353]"><img className='w-full h-full opacity-50' src={Img5} alt='' ></img></div>
            <span className='flex items-center gap-10 z-10'>
                <hr className='w-80 text-[#ffffff]'></hr>
                <span className='flex flex-col gap-3'>
                    <span className='text-5xl font-bold text-center text-[#ffffff]/90'>News & Insights</span>
                </span>
                <hr className='w-80 text-[#ffffff]'></hr>
            </span>
        </div>
        <div className='flex flex-col py-12 gap-12 '> 
            <span className='text-5xl flex justify-center text-[#0B3353]'>Market Insights</span>
            <span className='flex gap-6 justify-center'>
                <span className='flex flex-col'>
                    <span className='w-[23rem] h-[23rem] bg-[#000000]/70'>
                        <img className='w-full h-full object-cover' src={Img2} alt=''></img>
                    </span>
                    <span className='w-[23rem] px-8 flex text-center text-[#0B3353] text-xl font-bold py-3 border-b border-x border-[#0B3353]'>Q3 2023 Fixed Income Report & Q4 Outlook</span>
                </span>
                 <span className='flex flex-col'>
                    <span className='w-[23rem] h-[23rem] bg-[#000000]/70'>
                        <img className='w-full h-full object-cover' src={Img3} alt=''></img>
                    </span>
                    <span className='w-[23rem] px-8 flex text-center text-[#0B3353] text-xl font-bold py-3 border-b border-x border-[#0B3353]'>Q3 2023 Fixed Income Report & Q4 Outlook</span>
                </span>
                 <span className='flex flex-col'>
                    <span className='w-[23rem] h-[23rem] bg-[#000000]/70'>
                        <img className='w-full h-full object-cover' src={Img4} alt=''></img>
                    </span>
                    <span className='w-[23rem] px-8 flex text-center text-[#0B3353] text-xl font-bold py-3 border-b border-x border-[#0B3353]'>Q3 2023 Fixed Income Report & Q4 Outlook</span>
                </span>
            </span>
        </div>
    </div>
  )
}

export default NewsContent