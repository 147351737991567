import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import Img from '../assets/han.png';
import Img2 from '../assets/han3.png';
import Img3 from '../assets/han4.png';
import Img4 from '../assets/han5.png';
import Img5 from '../assets/han7.png';
import Img6 from '../assets/img1.png';
import Img7 from '../assets/img4.png';
import Icon1 from '../assets/creative.png';
import Icon2 from '../assets/dependability.png';
import Icon3 from '../assets/results.png';

const HomeContent = () => {
  return (
    <div>
        <NavBar />
        <div className='flex h-[31rem] justify-center bg-[#000000]/80 bg-cover bg-center relative'>
            <div className="absolute w-full h-full bg-[#0B3353]"><img className='w-full h-full opacity-50' src={Img6} alt='' ></img></div>
            <span className='flex items-center z-10'>
                <hr className='w-80 text-[#ffffff]'></hr>
                <span className='flex flex-col gap-3'>
                    <span className='text-5xl font-bold text-center text-[#ffffff]/90'>Your trusted partner.</span>
                    <span className='text-3xl font-medium text-center text-[#F6DBC2]'>Discovering opportunities by inspiring ideation</span>
                </span>
                <hr className='w-80 text-[#ffffff]'></hr>
            </span>
        </div>
        <div className='flex flex-col items-center py-20 gap-2'>
            <span className=' text-3xl font-semibold text-[#0B3353]'>Who We Are</span>
            <span className='text-2xl tracking-wide w-[45rem] text-center leading-none text-[#0B3353]/80'>We are engineers of value creation. We are multi asset investors/traders who also provide financial advisory.<span className=' text-red-500'>We are engineers of value creation. We are multi asset investors/traders who also provide financial advisory.</span></span>
        </div>
        
        <div className='flex flex-col h-fit bg-cover bg-center relative'>
            <div className="absolute w-full h-full bg-[#0B3353]"><img className='w-full h-full opacity-50' src={Img5} alt='' ></img></div>

            <div className='flex flex-col z-10 py-32'>
                <span className='flex flex-col pl-24 -mb-16'>
                    <span className='text-5xl text-[#ffffff]/80'>Guided by</span>
                    <span className='text-5xl text-[#ffffff]/80'>Enduring values</span>
                </span>
                <div className='flex items-center justify-center'>
                    <span className='w-[22rem] px-6 flex items-center flex-col h-[27rem] pt-32 gap-3 border-r border-[#ffffff]/80'>
                        <span className='w-28 h-28 '><img className='w-full h-full' src={Icon1} alt=''></img></span>
                        <span className='text-3xl text-[#ffffff]/90'>Creative </span>
                        <span className='text-xl flex text-center text-[#ffffff]/80 tracking-wider'>We adopt ingenious solutions and principles that help us stay ahead.</span>
                    </span>
                    <span className='w-[22rem] px-6 flex items-center flex-col h-[27rem] pt-32 gap-3 border-r border-[#ffffff]/80'>
                        <span className='w-28 h-28'><img className='w-full h-full' src={Icon2} alt=''></img></span>
                        <span className='text-3xl text-[#ffffff]/90'>Dependable</span>
                        <span className='text-xl flex text-center text-[#ffffff]/80 tracking-wider'>We ensure our stakeholders are up to date on the Company’s performance, position, and prospects.</span>
                    </span>
                    <span className='w-[22rem] px-6 flex items-center flex-col h-[27rem] pt-32 gap-3 border-r border-[#ffffff]/80'>
                        <span className='w-28 h-28'><img className='w-full h-full' src={Icon3} alt=''></img></span>
                        <span className='text-3xl text-[#ffffff]/90'>Result Oriented </span>
                        <span className='text-xl flex text-center text-[#ffffff]/80 tracking-wider'>We are focused on the big picture. This approach gives us the impetus we need to produce positive results.</span>
                    </span>
                </div>
            </div>
        </div>
        <div className='flex gap-10 bg-[#0B3353]'>
            <span className='flex flex-col gap-3 justify-center pl-24 w-[50%]'>
                {/* <span className='text-3xl font-semibold text-white'>what we do</span> */}
                <span className='text-3xl font-medium w-[35rem] text-white'>We use our global access and local expertise to generate alternative asset investments that create long term value. </span>
                {/* <span className='text-lg w-[35rem] tracking-wide text-[#000000]/80'>We combine decades of financial markets and alternative investment experience, driving capital where it creates superior returns.</span> */}
                <span className='flex gap-6 mt-5 w-[10rem] '>
                    <span className='px-3 py-2 text-[#0B3353] bg-[#F6DBC2] w-fit'>Learn more</span>
                </span>
            </span>
            <span className='w-[50%]'>
                <img src={Img} alt='' className='w-full h-[28rem] object-cover'></img>
            </span>
        </div>
        <div className='flex flex-col py-12 gap-12 '> 
            <span className='text-5xl flex justify-center text-[#0B3353]'>Market Insights</span>
            <span className='flex gap-6 justify-center'>
                <span className='flex flex-col'>
                    <span className='w-[23rem] h-[23rem] bg-[#000000]/70'>
                        <img className='w-full h-full object-cover' src={Img2} alt=''></img>
                    </span>
                    <span className='w-[23rem] px-8 flex text-center text-[#0B3353] text-xl font-bold py-3 border-b border-x border-[#0B3353]'>Q3 2023 Fixed Income Report & Q4 Outlook</span>
                </span>
                 <span className='flex flex-col'>
                    <span className='w-[23rem] h-[23rem] bg-[#000000]/70'>
                        <img className='w-full h-full object-cover' src={Img3} alt=''></img>
                    </span>
                    <span className='w-[23rem] px-8 flex text-center text-[#0B3353] text-xl font-bold py-3 border-b border-x border-[#0B3353]'>Q3 2023 Fixed Income Report & Q4 Outlook</span>
                </span>
                 <span className='flex flex-col'>
                    <span className='w-[23rem] h-[23rem] bg-[#000000]/70'>
                        <img className='w-full h-full object-cover' src={Img4} alt=''></img>
                    </span>
                    <span className='w-[23rem] px-8 flex text-center text-[#0B3353] text-xl font-bold py-3 border-b border-x border-[#0B3353]'>Q3 2023 Fixed Income Report & Q4 Outlook</span>
                </span>
            </span>
        </div>
        <div className='flex h-[27rem] items-center bg-cover bg-center relative'>
            <div className="absolute w-full h-full bg-[#0B3353]"><img className='w-full h-full opacity-40' src={Img7} alt='' ></img></div>
            <div className='flex flex-col gap-5 pl-24 z-10 text-[#ffffff]'>
                <span className='flex flex-col gap-1'>
                    <span className='text-5xl'>Unlock the markets</span>
                    <span className='text-5xl'>with us!</span>
                </span>
                <span className='px-3 py-2 text-[#0B3353] bg-[#F6DBC2] w-fit'>Contact Us</span>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default HomeContent;