import React from 'react'
import NewsContent from '../components/NewsContent'
import Footer from '../components/Footer'

const News = () => {
  return (
    <div>
        <NewsContent />
        <Footer />
    </div>
  )
}

export default News