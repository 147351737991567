import React from "react";
import Home from "./pages/Home";
import { Routes, Route} from 'react-router-dom';
import About from "./pages/About";
import News from "./pages/News";


function App() {
  return (
    <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/news' element={<News />}></Route>
    </Routes>
  );
}

export default App;
