import React from 'react'
import HomeContent from '../components/HomeContent';

const Home = () => {
  return (
    <div>
        <HomeContent />
    </div>
  )
}

export default Home;