import React from 'react'
import AboutContent from '../components/AboutContent';
import Footer from '../components/Footer';

const About = () => {
  return (
    <div>
        <AboutContent />
        <Footer />
    </div>
  )
}

export default About;